import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization } from '../../Session';
import cogoToast from 'cogo-toast';
import * as ROLES from '../../../constants/roles';
import AdminSettingsNavigation from './navigation';

const INITIAL_STATE = {
	error: null,
	submitted: false,
	settings: null,
	upload_hero: ""
};



class AdminSettingsSitephotos extends Component {
	constructor(props) {
		super(props);
		let settingsStorage = localStorage.getItem('settings');
		this.state = { 
			...INITIAL_STATE, 
			settings: settingsStorage ? JSON.parse(settingsStorage): {} 
		};
	}
	
	onSubmit = event => {
		event.preventDefault();
		
		const { settings, upload_hero } = this.state;
		let storageRef = this.props.firebase.storage;
		
		/* Upload images */
		if(upload_hero.length){
			/* Delete previous logo if existing */
			if(settings && settings.siteheroPath){
				let imagetRef = storageRef.ref().child(`${settings.siteheroPath}`);
				imagetRef.delete();
			}

			/* Upload new logo */
			let file = upload_hero[0];
			let path = `images/hero/${Math.random().toString(36).substring(7)}`;
			storageRef
				.ref(path)
				.put(file).then((snapshot) => {
					snapshot.ref.getDownloadURL().then((url) => {
						/* Apply the modification to the item directly without changing the current item index. */
						this.setState(prevState => ({
							settings: {
								...prevState.settings,
								siteheroUrl: url,
								siteheroPath: path,
							}
						}), () => {
							localStorage.setItem('settings', JSON.stringify(this.state.settings)); /* Update settings from localStorage */
							this.props.firebase.settings().doc('general').set({...this.state.settings}, {merge: true})
						});
					})
				})
				.catch(error => {
					this.setState({ error });
				});
		}
		this.setState({upload_hero: ""});

		/* Update user data in firebase */
		this.props.firebase.settings().doc('general').set({...settings}, {merge: true})
		.then(() => {
			localStorage.setItem('settings', JSON.stringify(settings)); /* Update settings from localStorage */
			cogoToast.success("Settings updated");
			this.setState({ submitted: true });

		}).catch(error => {
			this.setState({ error });
		});
		
	};
	

	
	onChangeInputFile(event) {
		const upload_hero = Array.from(event.target.files);
		this.setState({ upload_hero });   
	};

	render() {
		const { settings, error, submitted } = this.state;
		
		
					  
		return (
			<div>
				<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
					<span className="navbar-brand">Admin</span>
				</nav>
				
				<div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
					<div className="card mb-3">
						<div className="card-body">
							<AdminSettingsNavigation />
							
							<h4>Site Photo Settings</h4>
							<hr />

							<form>
				
								<div className="form-group">
									<label className="mr-2">Site hero <span className="text-danger">*</span></label>
									<label className="btn btn-purple">
										Change hero <input id="file" type="file" onChange={this.onChangeInputFile.bind(this)} className="d-none" multiple />
									</label>

									{settings && settings.siteheroUrl 
										? <div><img src={settings.siteheroUrl} alt="hero" height="150" /></div>
										: <p className="alert alert-danger w-25">No image</p>
									}
								</div>
								
								
								<button type="submit" className="btn btn-dark" onClick={this.onSubmit}>
									Update Settings
								</button>
								
								{error && <div className="alert alert-warning mt-3 w-50" role="alert">{error.message}</div>}
								
								{submitted &&
									<div className="alert alert-warning alert-dismissible fade show mt-3 w-50" role="alert">
										<strong>Warning:</strong> Please refresh this page in order for the new settings to take effect. 
										<button type="button" className="close" data-dismiss="alert" aria-label="Close">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
								}
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.ADMIN);
	
/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(AccountBasicFormBase));
   we just organize it like bellow:
*/
const AdminSettingsSitephotosForm = compose(
	withAuthorization(condition),
	withFirebase,
)(AdminSettingsSitephotos);

export default AdminSettingsSitephotosForm;