import React from 'react';

document.title = '404 Not Found';

const NotFound = () => (
	<div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
		<div className="card mb-3">
			<div className="card-body text-center py-5">
				<h1>404 Not Found</h1>
				<p>The content you are looking for does not exist.</p>
			</div>
		</div>
	</div>
);

export default NotFound;