import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import cogoToast from 'cogo-toast';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { isPriceKey, getKeywords, getKeycolor} from '../../helpers/global';
// import Checkbox from '../../helpers/checkbox'
// const items = [
// 	'small',
// 	'Large',
// 	'ExtraLarge',
//   ];

const INITIAL_STATE = {
	id: '',
	name: '',
	color: '',
	description: '',
	excerpt: '',
	category: '',
	price: '',
	salePrice: '',
	sku: '',
	stock: '',
	sizeoptionsandstock:'No Size',
	small:'',
	medium:'',
	large:'',
	xlarge:'',
	xxlarge:'',
	xxxlarge:'',
	StockType:'',
	StockValue:'',
	shippingMethod: 'Free shipping',
	shippingStandard: '',
	shippingExpedited: '',
	shippingSameDayDelivery: '',
	featured_image: '',
	error: null,
	loading: true,
	upload_files: [],
	images: [],
	color: [],
};


const stockOptionsObject = {
	'small': 'Small Size',
	'medium': 'Medium Size',
	'large': 'Large Size',
	'xlarge': 'XLarge Size',
	'xxlarge': 'XXLarge Size',
	'xxxlarge':'XXXLarge Size'
}
class UpdateProduct extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			...INITIAL_STATE,
			authUser: JSON.parse(localStorage.getItem('authUser')),
			settings: JSON.parse(localStorage.getItem('settings')),
			productGeneral: JSON.parse(localStorage.getItem('productGeneral')),
		};
	}
	

	onSubmit = event => {
		event.preventDefault();
		
		const { id, name, description, excerpt, category, price, salePrice, sku, stock, shippingMethod, shippingStandard, shippingExpedited, shippingSameDayDelivery, sizeoptionsandstock,small,medium, large,xlarge,xxlarge,xxxlarge, authUser, upload_files, images, color} = this.state;
		
		this.props.firebase.product(id).update({ 
			name, 
			keywords: getKeywords(name), 
			color,
			keycolor: getKeycolor(color),
			description, 
			excerpt, 
			category, 
			price: parseFloat(price),
			salePrice: salePrice ? parseFloat(salePrice) : 0, 
			sku, 
			stock: parseInt(stock),
			shippingMethod, 
			shippingStandard: shippingStandard ? parseFloat(shippingStandard) : 0, 
			shippingExpedited: shippingExpedited ? parseFloat(shippingExpedited) : 0, 
			shippingSameDayDelivery: shippingSameDayDelivery ? parseFloat(shippingSameDayDelivery) : 0, 
			sizeoptionsandstock,
			small: small ? parseFloat(small) : 0, 
			medium: medium ? parseFloat(medium) : 0, 
			large: large ? parseFloat(large) : 0, 
			xlarge: xlarge ? parseFloat(xlarge) : 0, 
			xxlarge: xxlarge ? parseFloat(xxlarge) : 0, 
			xxxlarge: xxxlarge ? parseFloat(xxxlarge) : 0, 
			author: authUser.uid, 
		
		}).then(() => {
			if(upload_files.length){
				const storageRef = this.props.firebase.storage;
				let counter = 0;
				
				upload_files.forEach((file, _index, array) => {
					let path = `images/products/${id}/${Math.random().toString(36).substring(7)}`;
					storageRef
						.ref(path)
						.put(file).then((snapshot) => {
							snapshot.ref.getDownloadURL().then((url) => {
								let newImage = {'path': path, 'url': url};
								images.push(newImage); /* Push new image to the images state */
								
								if (counter === array.length -1){
									this.setState({ images: images, upload_files: [] }); /* Update state to refelct new images in UI */
									this.props.firebase.product(id).update({images: images}); /* Insert update image list into the database */
								}
								
								counter++;
							})
						})
						.catch(error => {
							this.setState({ error });
						});
					
				});
			}
			this.setState({upload_files: []});
			cogoToast.success("Product Saved");
		})
		.catch(error => {
			this.setState({ error });
		});
	};
	

	onChangeInput = event => {
		this.setState({ [event.target.name]: event.target.value });
		
		/* Empty the value of shipping options if not selected */
		if(event.target.name === 'shippingMethod' && event.target.value !== 'Shipping options'){
			this.setState({shippingStandard: '', shippingExpedited: '', shippingSameDayDelivery: ''});
		}
	};
	onChangeInputa = event => {
		this.setState({ [event.target.name]: event.target.value });

	if(event.target.name === 'sizeoptionsandstock' && event.target.value !== 'Size options'){
		this.setState({small: '', medium: '', large: '', xlarge:'',xxlarge: '',xxxlarge: ''});

	}

}
	
	onChangeInputFile(event) {
		const upload_files = Array.from(event.target.files);
		this.setState({ upload_files });   
	};

	onChangeInputFile(event) {
		const upload_files = Array.from(event.target.files);
		this.setState({ upload_files });   
	};




	onDeleteItem = event => {
		event.preventDefault();
		
		const self = this;
		const { id } = this.state;
		
		if(window.confirm('Are you sure you want to delete this item?')){
			let product = this.props.firebase.product(id);

			product.get().then(snapshot => {
				let storageRef = this.props.firebase.storage;
				
				/* Delete product images */
				if(snapshot.data().images.length){
					snapshot.data().images.forEach((image) => {
						storageRef.ref().child(`${image.path}`).delete();
					});
				}
				
				/* Delete the product */
				product.delete()
					.then(() => {
						self.props.history.push(ROUTES.PRODUCTS);
					}).catch(error => {
						this.setState({ error });
					});
			}).catch(error => {
				this.setState({ error });
			})
		}
	};
	
	







	onDeleteImage = event => {
		let path = event.target.getAttribute('path');
		const { images, id, featured_image } = this.state;
		const self = this;
		
		if(window.confirm('Are you sure you want to delete this image?')){
			let storageRef = this.props.firebase.storage;
			let imageRef = storageRef.ref().child(`${path}`);
			let productRef = this.props.firebase.product(id);
			
			images.forEach((image, index, array) => {
				/* Found image */
				if(image.path === path){
					/* Remove image from array list */
					if(images.splice(index, 1)){
						/* Unset if featured image */
						if(image.url === featured_image){
							self.setState({featured_image: ''});
						}
						
						/* Delete image in storage */
						imageRef.delete()
						
						/* Save changes in firebase */
						productRef.update({'images': images, 'featured_image': self.state.featured_image}).then(() => {
							/* Update the images state */
							self.setState({ images: images });
						
							cogoToast.success('Image deleted');
						});
					}
				}
			});
			
		}
	};
	

	
	onFeaturedImage = event => {
		const { id } = this.state;
		const self = this;
		let url = event.target.getAttribute('url');
		let productRef = this.props.firebase.product(id);
		
		/* Update the images state */
		self.setState({ featured_image: url }, () => {
			/* Save changes in firebase */
			productRef.update({'featured_image': url}).then(() => {
				cogoToast.success('Image set as featured');
			});
		});
	};
	
	componentDidMount(){
		document.title = 'Update';
		// this.selectedCheckboxes = new Set();
		let product_id = this.props.match.params.id;
		let user_id = this.state.authUser.uid;

		
		if(product_id){
			/* Fetch product from our Firebase realtime database  */
			/* this.props.firebase.product() -- the "product" is taken from firebase.js  */




			this.unsubscribeProduct = this.props.firebase.product(product_id).onSnapshot(snapshot => { /* Use get() to only call it once (disable realtime) */
				const productObject = snapshot.data();
				
				if(productObject && productObject.author === user_id){
					document.title = 'Update: ' + productObject.name
					
					this.setState({
						...productObject,
						images: productObject.images ? productObject.images : [],
						colors: productObject.colors ? productObject.colors : [],
						id: product_id, 
						loading: false
					});
				} else {
					this.props.history.push(ROUTES.NOT_FOUND);
				}
				
			});
		}
	}

    
	// onSubmitSizes = event => {
	// 	const self = this
	// 	event.preventDefault();
	// 	const {productSizes, sizesName , sizesSlag, sizesStock } = this.state;


	// 	let newSizes = {name: sizesName, slag: sizesSlag, stock: sizesStock};
	// 	let updatedSizes = productSizes;

	// 	this.productSizes = productSizes.productSizes ? 
	// 	productSizes.productSizes: [];
		
	// 	updatedSizes.push(newSizes)

	// 	self.UpdateProduct(updatedSizes);
	// 	self.setState({sizesName:'', sizesSlag:'', sizesStock: ''});
	// };




	// onSubmitSizes = event => {
	// 	let path = event.target.getAttribute('path');
	// 	const {id, product, sizesName , sizesSlag, sizesStock } = this.state;
	// 	const self = this;
	// 	if(window.confirm('Are you sure you want to delete this image?')){
	// 		let productRef = this.props.firebase.product(id);
	// 		let newSizes = {name: sizesName, slag: sizesSlag, stock: sizesStock};
	// 		let updatedSizes = product;
	// 		productRef.get()
	// 		.then((snapshot) => snapshot.ref.getDownloadURL().then((url) => {
	// 			let newImage = {'path': path, 'url': url};
	// 			images.push(newImage); /* Push new image to the images state */
				
	// 			if (counter === array.length -1){
	// 				this.setState({ images: images, upload_files: [] }); /* Update state to refelct new images in UI */
	// 				this.props.firebase.product(id).update({images: images}); /* Insert update image list into the database */
	// 			}

	// 	updatedSizes.productSizes = product.productSizes ? 
	// 	product.productSizes: [];
		
	// 	updatedSizes.productSizes.push(newSizes)

	// 	this.updateProduct(updatedSizes);
	// 	this.setState({sizesName:'', sizesSlag:'', sizesStock: ''});
	// };

	// onDeleteSizes = (event, index) => {
	// 	event.preventDefault();
		
	// 	if(window.confirm('Are you sure you want to delete this Size?')){
	// 		const { product } = this.state;
			
	// 		let updatedSizes = product;
			
	// 		/* Remove item from the cloned cart state */
	// 		updatedSizes.productSizes.splice(index, 1); 
			
	// 		/* Set updated settings state */
	// 		this.updateProduct(updatedSizes);
	// 	}
	// }
	// onChange = event => {
	// 	event.persist(); /* Keep the original synthetic event around */
		
	// 	if(event.target.name === 'sizesName' || event.target.name === 'sizesSlag' || event.target.name === 'sizesStock'){
	// 		/* Update category name and slag */
	// 		this.setState({
	// 			[event.target.name]: event.target.value
	// 		});

	// 	} else {
	// 		/* Update other settings state */
	// 		this.setState(prevState => ({
	// 			product: {
	// 				...prevState.product,
	// 				[event.target.name]: event.target.value
	// 			}
	// 		}));
	// 	}
		
		
	// }
	
	/* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
	componentWillUnmount() {

	
		this.unsubscribeProduct && this.unsubscribeProduct();
	}
	
	render() {
		const { id, name, color, description, excerpt, category, price, salePrice, sku, stock, shippingMethod, shippingStandard, shippingExpedited, shippingSameDayDelivery, sizeoptionsandstock , small, medium, large,xlarge, xxlarge, xxxlarge, loading, error, images, featured_image, upload_files, settings } = this.state;
		const isInvalid = name === '' || description === '' || excerpt === '' || category === '' || price === '' || stock === '';
		const isIncompleteShipping = shippingMethod === 'Shipping options' && shippingStandard === '' && shippingExpedited === '' && shippingSameDayDelivery === '';
		const isIncompleteSize = sizeoptionsandstock === 'Size options' && small === '' && medium === '' && large === '';
		return (
			<div>
				{loading ? <div>Loading...</div> : 
					<div>
						<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
							<span className="navbar-brand">Update</span>
							<Link to="#" onClick={this.onDeleteItem} className="btn btn-sm btn-link text-white ml-auto" title="Delete">
								<span className="material-icons align-middle">delete</span>
							</Link>
							<Link to={{pathname: '/products/view/' + id}} className="btn btn-sm btn-link text-white" title="View">
								<span className="material-icons align-middle">remove_red_eye</span>
							</Link>
						</nav>
						
						<div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
							<div className="card mb-3">
								<div className="card-body">
									<form onSubmit={this.onSubmit}>
										<div className="form-group">
											<label>Name *</label>
											<input name="name" value={name} onChange={this.onChangeInput} type="text" className="form-control w-50" />
										</div>
										<div className="form-group">
											<label>Colors *</label>
											<input name="color" value={color} onChange={this.onChangeInput} type="text" className="form-control w-50" />
										</div>
										<div className="form-group">
											<label>Description *</label>
											<CKEditor
												editor={ ClassicEditor }
												data={description}
												config={ {
													toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
												} }
												onChange={ ( event, editor ) => {
													const data = editor.getData();
													this.setState({description: data});
												} }
											/>
										</div>
										<div className="form-group">
											<label>Short Description *</label>
											<textarea rows="5" onChange={this.onChangeInput} name="excerpt" value={excerpt} className="form-control"></textarea>
										</div>
										
										<div className="form-group">
											<label>Category <span className="text-danger">*</span></label>
											<select className="form-control w-25" name="category" onChange={this.onChangeInput} value={category}>
												<option value="">Select Category</option>
												{settings.productCategories && settings.productCategories.map((cat, index) => (
													<option key={index} value={cat.slag}>{cat.name}</option>
												))}
											</select>
										</div>
										{/* <div className="form-group">
											<label>Size <span className="text-danger">*</span></label>
											<select className="form-control w-25" name="sizes" onChange={this.onChangeInput} value={sizes}>
												<option value="">Select Size</option>
												{settings.productSize && settings.productSize.map((siz, index) => (
													<option key={index} value={siz.slag}>{siz.name}</option>
												))}
											</select>
										</div>
										<div className="form-group border p-3 mt-3">
										{settings.productSize 
											?
												<div>
													{settings.productSize.map((size, index) => (
														<span className="py-2 px-3 mr-1 mb-1 bg-purple d-inline-block" key={index}>
															{size.name} 
															<Link to="#" className="badge badge-light ml-2" onClick={(event) => this.onaddSize(event, index)}>
																<i className="material-icons align-middle font-weight-bold" style={{fontSize: '10px'}}>close</i>
															</Link>
														</span>
													))}
												</div>
											:
												<div className="text-danger">No categories available</div>
										}
									</div> */}
							 
										
										<label>Regular Price *</label>
										<div className="input-group mb-3 w-25">
											<div className="input-group-prepend">
												<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
											</div>
											<input name="price" value={price} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInput} type="text" className="form-control" />
										</div>

										
										
										<label>Sale Price</label>
										<div className="input-group mb-3 w-25">
											<div className="input-group-prepend">
												<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
											</div>
											<input name="salePrice" value={salePrice} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInput} type="text" className="form-control" placeholder="Leave this blank if not on sale"  />
										</div>
										<div className="form-group">
											<label>SKU</label>
											<input name="sku" value={sku} onChange={this.onChangeInput} type="text" className="form-control w-25" />
										</div>
										<div className="form-group">
											<label>Stock *</label>
											<input name="stock" value={stock} onChange={this.onChangeInput} type="number" className="form-control w-25" min="0" />
										</div>
										
										<div className="form-group">
											<label>Shipping Method (per item) <span className="text-danger">*</span></label>
											<select className="form-control w-25" name="shippingMethod" onChange={this.onChangeInput} value={shippingMethod}>
												<option value="Free shipping">Free shipping</option>
												<option value="Shipping options">Shipping options</option>
												<option value="Local pickup">Local pickup</option>
											</select>
											
										</div>
								
										{shippingMethod === 'Shipping options' &&
											<div>
												{isIncompleteShipping &&
													<em className="text-danger d-block mb-2 ml-lg-4">Enter a cost in at least one of the shipping options bellow:</em>
												}
												<ul>
													<li>
														<label>Standard shipping cost</label>
														<div className="input-group mb-3 w-25">
															<div className="input-group-prepend">
																<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
															</div>
															<input name="shippingStandard" value={shippingStandard} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInput} type="text" className="form-control" />
														</div>
													</li>
													<li>
														<label>Expedited shipping cost</label>
														<div className="input-group mb-3 w-25">
															<div className="input-group-prepend">
																<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
															</div>
															<input name="shippingExpedited" value={shippingExpedited} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInput} type="text" className="form-control" />
														</div>
													</li>
													<li>
														<label>Same day delivery cost</label>
														<div className="input-group mb-3 w-25">
															<div className="input-group-prepend">
																<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
															</div>
															<input name="shippingSameDayDelivery" value={shippingSameDayDelivery} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInput} type="text" className="form-control" />
														</div>
													</li>
												</ul>
											</div>
										}
										<div className="form-group">
									<label>sizeoptionsandstock (per item) <span className="text-danger">*</span></label>
									<select className="form-control w-25" name="sizeoptionsandstock" onChange={this.onChangeInputa} value={sizeoptionsandstock}>
										<option value="No Size">No Size</option>
										<option value="Size options">Size options</option>
									</select>
									
								</div>
								
								{ sizeoptionsandstock === 'Size options' &&
									<div>
										{isIncompleteSize &&
											<em className="text-danger d-block mb-2 ml-lg-4">Enter a size stock in one of the options bellow:</em>
										}
										<ul>
											<li>
												<label>Small</label>
												<div className="input-group mb-3 w-25">
											
													<input name="small" value={small} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInputa} type="text" className="form-control" />
												</div>
											</li>
											
											<li>
												<label>Medium</label>
												<div className="input-group mb-3 w-25">
												
													<input name="medium" value={medium} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInputa} type="text" className="form-control" />
												</div>
											</li>
											<li>
												<label>Large</label>
												<div className="input-group mb-3 w-25">
											
												
													<input name="large" value={large} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInputa} type="text" className="form-control" />
												</div>
											</li>
											<li>
												<label>Extra Large</label>
												<div className="input-group mb-3 w-25">
												
												
													<input name="xlarge" value={xlarge} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInputa} type="text" className="form-control" />
												</div>
											</li>
											<li>
												<label> XXLarge</label>
												<div className="input-group mb-3 w-25">
											
												
													<input name="xxlarge" value={xxlarge} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInputa} type="text" className="form-control" />
												</div>
											</li>
											<li>
												<label>XXXLarge</label>
												<div className="input-group mb-3 w-25">
												
												
													<input name="xxxlarge" value={xxxlarge} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChangeInputa} type="text" className="form-control" />
												</div>
											</li>
										</ul>
									</div>
								}


										
										<label>Images</label>
										<div className="mb-3">
											{images.length ? 
												<div className="border p-3">
													<div className="table-responsive">
														<table className="table w-auto">
															<tbody>
															{images.map(image => (
																<tr key={image.path}>
																	<td><img src={image.url} className="" alt="" height="100" /></td>
																	<td>
																		<span className={`material-icons f-15 btn ${featured_image === image.url ? 'btn-success' : 'btn-light'}`} onClick={this.onFeaturedImage} url={image.url}>star</span> &nbsp; 
																		<span className="material-icons f-15 btn btn-light" onClick={this.onDeleteImage} path={image.path}>delete</span>
																	</td>
																</tr>
															))}
															</tbody>
														</table>
													</div>
												</div>
												:
												<div className="border p-3 text-danger">No images available</div>
											}
										</div>
										
										<div className="form-group">
											<label className="btn btn-light">
												<span className="material-icons f-15 align-middle font-weight-bold">add</span> Add Images 
												<input id="file" type="file" onChange={this.onChangeInputFile.bind(this)} className="d-none" multiple />
											</label>
											{upload_files.length > 0 && <span className="text-success ml-2">{upload_files.length} File(s) selected</span>}
										</div>
										{/* <label>Sizes</label>
										<div className="mb-3">
											{productSizes.length ? 
												<div className="border p-3">
													<div className="table-responsive">
														<table className="table w-auto">
															<tbody>
															{productSizes.map(sizes => (
																<tr key={sizes.sizeSlag}>
																	<td>productSizes </td>
																	<td>
																		<span className={`material-icons f-15 btn ${featured_image === image.url ? 'btn-success' : 'btn-light'}`} onClick={this.onFeaturedImage} url={image.url}>star</span> &nbsp; 
																		<span className="material-icons f-15 btn btn-light" onClick={this.onDeleteImage} path={image.path}>delete</span>
																	</td>
																</tr>
															))}
															</tbody>
														</table>
													</div>
												</div>
												:
												<div className="border p-3 text-danger">No images available</div>
											}
										</div> */}
										{/* <div> */}
        {/* <input type="text" onChange={ (e) => this.onInputChange(e) } />
        <button onClick={ () => this.addItem() }>Add item</button>
        { this.listItems() }
      </div> */}
										{/* <div className="form-group">
										{productSizes
											?
												<div>
													{productSizes.map((size, index) => (
														<span className="py-2 px-3 mr-1 mb-1 bg-purple d-inline-block" key={index}>
															{size.name} {size.slag} {size.stock}
															<Link to="#" className="badge badge-light ml-2" onClick={(event)=> this.onChangesizes.bind(event)}><i className="material-icons align-middle font-weight-bold" multiple style={{fontSize: '10px'}}>close</i>
															</Link>
														</span>
													))}
												</div>
											:
												<div className="text-danger">No categories available</div>
										}
										{productSizes.length > 0 && <span className="text-success ml-2">{productSizes.length} File(s) selected</span>}
										</div>
										<div> */}
								

											{/* <label className="btn btn-light">
												<span className="material-icons f-15 align-middle font-weight-bold">add</span> Add Sizes 
												<input 									
												name="sizesSlag"
												value={sizesName}
												onClick={this.onChangesizes.bind(this)} className="form-control"/>
											</label>
											{productSizes.length > 0 && <span className="text-success ml-2">{productSizes.length} File(s) selected</span>}

											<button disabled={isInvalidSizes} type="submit" className="btn btn-dark" onClick={this.onChangesizes.bind(this,)}>
										Add Sizes
									</button> 
										</div> */}

										
										<div className="mt-5">
											<button disabled={isInvalid || isIncompleteShipping} type="submit" className="btn btn-dark">Update Product</button> {isInvalid && <em className="ml-2 text-danger">Please fill up all required fields</em>}
										</div>
									</form>
{/* <form>
								<div className="form-group mb-3">
									<label className="font-weight-bold">Manage Product Sizes:</label>
								</div>
								<div className="pl-4 mb-5">
									<div className="form-group mb-2">
										<label className="mb-1">Sizes Name<span className="text-danger">*</span></label>
										<input
											name="sizeName"
											value={sizesName}
											onChange={this.onChange}
											type="text"
											className="form-control w-25"
										/>
									</div>	
									<div className="form-group mb-2">
										<label className="mb-1">Sizes Slag (no spaces) <span className="text-danger">*</span></label>
										<input
											name="sizesSlag"
											value={sizesSlag}
											onKeyPress={(event) => noSpace(event)}
											onChange={this.onChange}
											type="text"
											className="form-control w-25"
										/>
									</div>	
									<div className="form-group mb-2">
										<label className="mb-1">Size Stock<span className="text-danger">*</span></label>
										<input
											name="sizesStock"
											value={sizesStock}
											onChange={this.onChange}
											type="text"
											className="form-control w-25"
										/>
									</div>
								
									<button disabled={isInvalidSizes} type="submit" className="btn btn-dark" onClick={this.onSubmitSizes}>
										Add Sizes
									</button>
									
									<div className="border p-3 mt-3">
										{productSizes ? 
												<div>
											
													{productSizes.map((sizes, index) => (
														<span className="py-2 px-3 mr-1 mb-1 bg-purple d-inline-block" key={index}>
															{sizes.sizeSlag} 
															<Link to="#" className="badge badge-light ml-2" onClick={(event) => this.onDeleteSizes(event, index)}>
																<i className="material-icons align-middle font-weight-bold" style={{fontSize: '10px'}}>close</i>
															</Link>
														</span>
													))}
												</div>
											:
												<div className="text-danger">No categories available</div>
										}
									</div>
								</div>
								</form> */}
								{/* <div className="container">
        <div className="row">
          <div className="col-sm-12">

            <form onSubmit={this.handleFormSubmit}>
              {this.createCheckboxes()}

              <button className="btn btn-default" type="submit">Save</button>
            </form>

          </div>
        </div>
      </div> */}
									
								</div>
							</div>
						</div>
					</div>
				}
				
				{error && <div className="alert alert-warning">{error.message}</div>}
						
			</div>
		);
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.SELLER);

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(UpdateProduct));
   we just organize it like bellow:
*/
const UpdateProductPage = compose(
	withAuthorization(condition),
	withRouter,
	withFirebase,
)(UpdateProduct);

export default UpdateProductPage;