import React, { Component } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { withAuthorization } from '../../Session';
import cogoToast from 'cogo-toast';
import * as ROLES from '../../../constants/roles';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AdminSettingsNavigation from './navigation';

const INITIAL_STATE = {
	error: null,
	settings: null,
	categoryName: '',
	categorySlag: '',
};

class AdminSettingsCheckout extends Component {
	constructor(props) {
		super(props);
		let settingsStorage = localStorage.getItem('settings');
		this.state = { 
			...INITIAL_STATE, 
			settings: settingsStorage ? JSON.parse(settingsStorage): {} 
		};
	}
	
	componentDidMount() {
		document.title = 'Admin - Checkout Settings';
	}
	
	onSubmit = event => {
		event.preventDefault();
		
		const { settings } = this.state;
		
		/* Update user data in firebase */
		this.props.firebase.settings().doc('general').set({...settings}, {merge: true})
		.then(() => {
			localStorage.setItem('settings', JSON.stringify(settings)); /* Update settings from localStorage */
			cogoToast.success("Settings updated");
			
		}).catch(error => {
			this.setState({ error });
		});
		
	};
	
	onChange = event => {
		event.persist(); /* Keep the original synthetic event around */
		
		/* Update other settings state */
		this.setState(prevState => ({
			settings: {
				...prevState.settings,
				[event.target.name]: event.target.value
			}
		}));	
	};
  
	render() {
		const { settings, error } = this.state;
		
		const isInvalid =
			(settings && settings.bankTransferInstructions) === '' ||
			(settings && settings.cashOnDeliveryInstructions) === '';
			
		return (
			<div>
				<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
					<span className="navbar-brand">Admin</span>
				</nav>
				
				<div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
					<div className="card mb-3">
						<div className="card-body">
							<AdminSettingsNavigation />
							
							<h4>Checkout Settings</h4>
							<hr />
							<form>
								<div className="form-group">
									<label>Bank Transfer Instructions <span className="text-danger">*</span></label>
									<CKEditor
										editor={ ClassicEditor }
										data={settings && settings.bankTransferInstructions}
										config={ {
											toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState(prevState => ({
												settings: {
													...prevState.settings,
													bankTransferInstructions: data
												}
											}));
										} }
									/>
								</div>
								
								<div className="form-group">
									<label>Cash On Deliver Instructions <span className="text-danger">*</span></label>
									<CKEditor
										editor={ ClassicEditor }
										data={settings && settings.cashOnDeliveryInstructions}
										config={ {
											toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState(prevState => ({
												settings: {
													...prevState.settings,
													cashOnDeliveryInstructions: data
												}
											}));
										} }
									/>
								</div>
								
								<button disabled={isInvalid} type="submit" className="btn btn-dark" onClick={this.onSubmit}>
									Update Settings
								</button>
								
								{error && <div className="alert alert-warning" role="alert">{error.message}</div>}
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.ADMIN);
	
/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(AccountBasicFormBase));
   we just organize it like bellow:
*/
const AdminSettingsCheckoutForm = compose(
	withAuthorization(condition),
	withFirebase,
)(AdminSettingsCheckout);

export default AdminSettingsCheckoutForm;