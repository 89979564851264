import React from 'react';
import ProductsGeneralContext from './context';
import { withFirebase } from '../Firebase';

const withProductsGeneral = Component => {
	class withProductsGeneral extends React.Component {
		constructor(props) {
			super(props);

            this.state = {
				productproductsGeneral: JSON.parse(localStorage.getItem('productsGeneral')),
			};
		}

        componentDidMount() {
			this.props.firebase.products().doc('general').get().then(snapshot => {if(snapshot.data()){
                localStorage.setItem('productsGeneral', JSON.stringify(snapshot.data()));
                this.setState({ productsGeneral: snapshot.data() });

            } else {
                localStorage.removeItem('productsGeneral');
                this.setState({ productsGeneral: null });
            }
        });
    }

    render() {
        return (
            <ProductsGeneralContext.Provider value={this.state.productsGeneral}>
                <Component {...this.props} />
            </ProductsGeneralContext.Provider>
        );
    }
}
return withFirebase(withProductsGeneral);
};

export default withProductsGeneral;