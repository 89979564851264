import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import cogoToast from 'cogo-toast';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { isPriceKey, getKeywords, getKeycolor} from '../../helpers/global';





const INITIAL_STATE = {
	name: '',
	description: '',
	excerpt: '',
	category: '',
	price: '',
	salePrice: '',
	sku: '',
	stock: '',
	shippingMethod: 'Free shipping',
	shippingStandard: '',
	shippingExpedited: '',
	shippingSameDayDelivery: '',
	sizeStock:'No Size',
	small:'',
	medium:'',
	large:'',
	xlarge:'',
	xxlarge:'',
	xxxlarge:'',
	color:'',
	error: null,
	size:null,
	productsGeneral: null,

};



    

class CreateProduct extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			...INITIAL_STATE,
			authUser: JSON.parse(localStorage.getItem('authUser')),
			settings: JSON.parse(localStorage.getItem('settings')),
			productsGeneral: JSON.parse(localStorage.getItem('productsGeneral'))
		};
	}
	
	componentDidMount() {
		document.title = 'New Product';
	}

	// updateSize(size){	
	// 	/* Update user data in firebase */
	// 	this.props.firebase.products().doc().set({...size}, {merge: true})
	// 	.then(() => {
	// 		localStorage.setItem('size', JSON.stringify(size)); /* Update settings from localStorage */
			
	// 		this.setState({size: size});
			
	// 		cogoToast.success("Updated");
			
	// 	}).catch(error => {
	// 		this.setState({ error });
	// 	});
	// }


	
	onSubmit = event => {
		const self = this;
		const { authUser, name, description, excerpt, category, price, salePrice, sku, stock, shippingMethod, shippingStandard, shippingExpedited, shippingSameDayDelivery, sizeStock,small,medium, large,xlarge,xxlarge,xxxlarge, color} = this.state;
		const productsRef = self.props.firebase.products().doc();
		
		productsRef.set({
			name,
			keywords: getKeywords(name),
			color,
			colorsmall: small ? parseFloat(small) : 0, 
			colormedium: medium ? parseFloat(medium) : 0, 
			colorlarge: large ? parseFloat(large) : 0, 
			colorxlarge: xlarge ? parseFloat(xlarge) : 0, 
			colorxxlarge: xxlarge ? parseFloat(xxlarge) : 0, 
			colorxxxlarge: xxxlarge ? parseFloat(xxxlarge) : 0, 
			keycolor: getKeycolor(color),
			description,
			excerpt,
			category,
			price,
			salePrice: salePrice ? parseFloat(salePrice) : 0, 
			sku, 
			stock: parseInt(stock),
			
			shippingMethod,
			shippingStandard: shippingStandard ? parseFloat(shippingStandard) : 0, 
			shippingExpedited: shippingExpedited ? parseFloat(shippingExpedited) : 0, 
			shippingSameDayDelivery: shippingSameDayDelivery ? parseFloat(shippingSameDayDelivery) : 0, 
			sizeStock,
			small: small ? parseFloat(small) : 0, 
			medium: medium ? parseFloat(medium) : 0, 
			large: large ? parseFloat(large) : 0, 
			xlarge: xlarge ? parseFloat(xlarge) : 0, 
			xxlarge: xxlarge ? parseFloat(xxlarge) : 0, 
			xxxlarge: xxxlarge ? parseFloat(xxxlarge) : 0, 
			author: authUser.uid,
			createdAt: new Date().getTime()
		})
		.then(() => {

			self.setState({ ...INITIAL_STATE });
			self.props.history.push(ROUTES.PRODUCTS + '/update/' + productsRef.id);
			cogoToast.success("Product saved, you may continue editing.");
			
		})
		.catch(error => {
			this.setState({ error });
			
		});

		event.preventDefault();
	};




	onChange = event => {
		this.setState({ [event.target.name]: event.target.value });
		
		/* Empty the value of shipping options if not selected */
		if(event.target.name === 'shippingMethod' && event.target.value !== 'Shipping options'){
			this.setState({shippingStandard: '', shippingExpedited: '', shippingSameDayDelivery: ''});
		}
	

	};
onChange = event => {
		this.setState({ [event.target.name]: event.target.value });

	if(event.target.name === 'sizeStock' && event.target.value !== 'Size options'){
		this.setState({small: '', medium: '', large: '', xlarge:'',xxlarge: '',xxxlarge:'' });

	}

}


		
// }




	
	// Checkbox = (productsSlider) => {

	// const self = this
		
	// const [checkedState, setCheckedState] = self.useState(
	// 	new Array(productsSlider.length).fill(false)
	//   );
	
	//   const [total, setTotal] = self.useState(0);
	
	//   const handleOnChange = (position) => {
	// 	const updatedCheckedState = checkedState.map((item, index) =>
	// 	  index === position ? !item : item
	// 	);
	
	// 	setCheckedState(updatedCheckedState);
	
	// 	const totalPrice = updatedCheckedState.reduce(
	// 	  (sum, currentState, index) => {
	// 		if (currentState === true) {
	// 		  return sum + productsSlider[index].stockplus;
	// 		}
	// 		return sum;
	// 	  },
	// 	  0
	// 	);
	
	// 	setTotal(totalPrice);
	// 	}
	// }

	//onChange = event => {
	// 	this.setState({ [event.target.name]: event.target.value }); /* Keep the original synthetic event around */
			
	// 		if(event.target.name === 'sizeSlang' || event.target.name === 'sizeStock'){
	// 			/* Update category name and slag */
	// 			this.setState({
	// 				[event.target.name]: event.target.value
	// 			});
	
	// 		}
	// 			}));
	// 		}
			
			
		// };


	// toggleCheckbox = label => {
	// 	if (this.selectedCheckboxes.has(label)) {
	// 	  this.selectedCheckboxes.delete(label);
	// 	} else {
	// 	  this.selectedCheckboxes.add(label);
	// 	}
	//   }
	  
  
	//   onchecksubmitchange = event => {
	// 	event.preventDefault();
	// 	for (const checkbox of this.selectedCheckboxes) {
	// 	  console.log(checkbox, 'is selected.');
	// 	}
		
	//   }
	
	//   createCheckbox = label => (
	// 	<Checkbox
	// 			label={label}
	// 			handleCheckboxChange={this.toggleCheckbox}
	// 			key={label}
	// 		/>
	//   )
	
	//   createCheckboxes = () => (
	// 	items.map(this.createCheckbox)
	//   )
	  


	onChangeCheckbox = event => {
		this.setState({ [event.target.name]: event.target.checked });
	};

	onChangeCheckbox = event => {
		this.setState({ [event.target.name]: event.target.checked });
	};

  
	render() {
		const { name, description, excerpt, category, price, salePrice,  sku, stock, shippingMethod, shippingStandard, shippingExpedited, shippingSameDayDelivery, error, settings, productsGeneral, sizeStock, small, medium, large,xlarge, xxlarge, xxxlarge, color } = this.state;
		const isInvalid = name === '' || description === '' || excerpt === '' || category === '' || price === '' || stock === '' 
		const isIncompleteShipping = shippingMethod === 'Shipping options' && shippingStandard === '' && shippingExpedited === '' && shippingSameDayDelivery === ''
		
		const isIncompleteSize = sizeStock === 'Size options' && small === '' && medium === '' && large === ''

		let productsSlider = [];
		if(productsGeneral && productsGeneral.productSizes && productsGeneral.productSizes.length){
			productsGeneral.productSizes.forEach((size, _index, array) => {
				let newSize = {'productGeneralName': size.id, 'sizeGeneralName': size.name, 'sizeGeneralslag' : size.slag, 'sizeGeneralstock': size.stock};
				productsSlider.push(newSize); /* Push new image to the imagesSlider state */
			});
		}
		
		
		return (
			<div>
				<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
					<span className="navbar-brand">New</span>
				</nav>
				<div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
					<div className="card mb-3">
						<div className="card-body">
							<form onSubmit={this.onSubmit}>
								<div className="form-group">
									<label>Name <span className="text-danger">*</span></label>
									<input name="name" value={name} onChange={this.onChange} type="text" className="form-control w-50" />
								</div>
								<div className="form-group">
									<label>Color <span className="text-danger">*</span></label>
									<input name="color" value={color} onChange={this.onChange} type="text" className="form-control w-50" />
								</div>
								<div className="form-group">
									<label>Description <span className="text-danger">*</span></label>
									<CKEditor
										editor={ ClassicEditor }
										data={description}
										config={ {
											toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]
										} }
										onChange={ ( event, editor ) => {
											const data = editor.getData();
											this.setState({description: data});
										} }
									/>

							</div>
					




								<div className="form-group">
									<label>Short Description <span className="text-danger">*</span></label>
									<textarea rows="2" onChange={this.onChange} name="excerpt" value={excerpt} className="form-control"></textarea>
								</div>
								
								<div className="form-group">
									<label>Category <span className="text-danger">*</span></label>
									<select className="form-control w-25" name="category" onChange={this.onChange} value={category}>
										<option value="">Select Category</option>
										{settings.productCategories && settings.productCategories.map((cat, index) => (
											<option key={index} value={cat.slag}>{cat.name}</option>
										))}
									</select>
									{!settings.productCategories && 
										<div className="alert alert-danger my-3 w-50" role="alert">
											<strong>Warning:</strong> There are currently no categories available, please contact your administrator and request to add new categories.
										</div>
									}
								</div>
									
								<label>Regular Price <span className="text-danger">*</span></label>
								<div className="input-group mb-3 w-25">
									<div className="input-group-prepend">
										<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
									</div>
									<input name="price" value={price} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
								</div>
								
								<label>Sale Price (optional)</label>
								<div className="input-group mb-3 w-25">
									<div className="input-group-prepend">
										<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
									</div>
									<input name="salePrice" value={salePrice} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" placeholder="Leave this blank if not on sale" />
								</div>
								<div className="form-group">
									<label>SKU (optional)</label>
									<input name="sku" value={sku} onChange={this.onChange} type="text" className="form-control w-25" />
								</div>
								
								<div className="form-group">
									<label>Stock Total <span className="text-danger">*</span></label>
									<input name="stock" value={stock} onChange={this.onChange} type="number" min="0" className="form-control w-25" />
								</div>

								<div className="form-group">
									<label>Shipping Method (per item) <span className="text-danger">*</span></label>
									<select className="form-control w-25" name="shippingMethod" onChange={this.onChange} value={shippingMethod}>
										<option value="Free shipping">Free shipping</option>
										<option value="Shipping options">Shipping options</option>
										<option value="Local pickup">Local pickup</option>
									</select>
									
								</div>
								
								{shippingMethod === 'Shipping options' &&
									<div>
										{isIncompleteShipping &&
											<em className="text-danger d-block mb-2 ml-lg-4">Enter a cost in at least one of the shipping options bellow:</em>
										}
										<ul>
											<li>
												<label>Standard shipping cost</label>
												<div className="input-group mb-3 w-25">
													<div className="input-group-prepend">
														<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
													</div>
													<input name="shippingStandard" value={shippingStandard} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
											
											<li>
												<label>Expedited shipping cost</label>
												<div className="input-group mb-3 w-25">
													<div className="input-group-prepend">
														<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
													</div>
													<input name="shippingExpedited" value={shippingExpedited} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
											
											<li>
												<label>Same day delivery cost</label>
												<div className="input-group mb-3 w-25">
													<div className="input-group-prepend">
														<span className="input-group-text f-13">{settings && settings.currencySymbol}</span>
													</div>
													<input name="shippingSameDayDelivery" value={shippingSameDayDelivery} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
										</ul>
									</div>
								}
								<div className="form-group">
									<label>sizeStock (per item) <span className="text-danger">*</span></label>
									<select className="form-control w-25" name="sizeStock" onChange={this.onChange} value={sizeStock}>
										<option value="No Size">No Size</option>
										<option value="Size options">Size options</option>
									</select>
									
								</div>
								
								{ sizeStock === 'Size options' &&
									<div>
										{isIncompleteSize &&
											<em className="text-danger d-block mb-2 ml-lg-4">Enter a The Stock of each size below:</em>
										}
										<ul>
											<li>
												<label>Small</label>
												<div className="input-group mb-3 w-25">
											
													<input name="small" value={small} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
											
											<li>
												<label>Medium</label>
												<div className="input-group mb-3 w-25">
												
													<input name="medium" value={medium} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
											<li>
												<label>Large</label>
												<div className="input-group mb-3 w-25">
											
												
													<input name="large" value={large} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
											<li>
												<label>Extra Large</label>
												<div className="input-group mb-3 w-25">
												
												
													<input name="xlarge" value={xlarge} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
											<li>
												<label> XXLarge</label>
												<div className="input-group mb-3 w-25">
											
												
													<input name="xxlarge" value={xxlarge} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
											<li>
												<label>XXXLarge</label>
												<div className="input-group mb-3 w-25">
												
												
													<input name="xxxlarge" value={xxxlarge} onKeyPress={(event) => isPriceKey(event)} onChange={this.onChange} type="text" className="form-control" />
												</div>
											</li>
										</ul>
									</div>
								}
								
								
								<div className="mt-5">
									<button disabled={isInvalid || isIncompleteShipping || isIncompleteSize} type="submit" className="btn btn-dark">Create Product</button> {isInvalid && <em className="ml-2 text-danger">Please fill up all required fields</em>}
								</div>
							
								{error && <div className="alert alert-warning">{error.message}</div>}
							
						
								
							</form>
						</div>
					</div>
				</div>
				
			</div>
		);
									
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.SELLER);

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(CreateProduct));
   we just organize it like bellow:
*/
const CreateProductPage = compose(
	withAuthorization(condition),
	withRouter,
	withFirebase,
)(CreateProduct);

export default CreateProductPage;