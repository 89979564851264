import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import AdminSettingsNavigation from '../Admin/settings/navigation';
import cogoToast from 'cogo-toast';
import {  noSpace } from '../../helpers/global';




const INITIAL_STATE = {
    error: null,
	productsGeneral: null,
    productGeneralName:'',
	sizeGeneralName: '',
	sizeGeneralSlag: '',
	sizeGeneralStock:'',
};

class ADMINPRODUCTSGENERAL extends Component {
    constructor(props) {
		super(props);
		let productsGeneralStorage = localStorage.getItem('productsGeneral');
		this.state = { 
			...INITIAL_STATE, 
			productsGeneral: productsGeneralStorage ? JSON.parse(productsGeneralStorage): {} 
		};
	}

    componentDidMount() {
		document.title = 'Admin - Product sizes';
	}

    updateProductsGeneral(productsGeneral){
        
        
        this.props.firebase.products().doc('general').
        set({...productsGeneral}, {merge: true})
		.then(() => {
            localStorage.setItem('productsGeneral', JSON.stringify(productsGeneral));
            
            this.setState({productsGeneral: productsGeneral});
            cogoToast.success("Updated");
            		
		}).catch(error => {
			this.setState({ error });
		});
	}

    onSubmit = event => {
		event.preventDefault();
		const { productsGeneral } = this.state;

        this.updateProductsGeneral(productsGeneral);
	};

    onSubmitProductSize = event => {
		event.preventDefault();
        const { productsGeneral, productGeneralName, sizeGeneralName, sizeGeneralSlag, sizeGeneralStock } = this.state;

        let newItemSize = {id: productGeneralName, name: sizeGeneralName, slag: sizeGeneralSlag, stock: sizeGeneralStock};
		let updatedProductsGeneral =productsGeneral;

        updatedProductsGeneral.productSizes = productsGeneral.productSizes ? productsGeneral.productSizes: [];
        updatedProductsGeneral.productSizes.push(newItemSize)


        this.updateProductsGeneral(updatedProductsGeneral);

        this.setState({productGeneralName:'', sizeGeneralName: '', sizeGeneralSlag: '', sizeGeneralStock:''});
		
			
	};

    onDeleteProductGeneralSize = (event, index) => {
		event.preventDefault();
		
		if(window.confirm('Are you sure you want to delete this product Size?')){
			const { productsGeneral } = this.state;
			
			let updatedProductsGeneral = productsGeneral;
			
			/* Remove item from the cloned cart state */
			updatedProductsGeneral.productSizes.splice(index, 1); 
			
			/* Set updated settings state */
			this.updateProductsGeneral(updatedProductsGeneral);
		}
	}

    onChange = event => {
		event.persist();

        if(event.target.name === 'productGeneralName' || event.target.name === 'sizeGeneralName' ||event.target.name === 'sizeGeneralSlag' || event.target.name === 'sizeGeneralStock'){

            this.setState({
				[event.target.name]: event.target.value
			});


        } else {
            this.setState(prevState => ({
				products: {
					...prevState.productsGeneral,
					[event.target.name]: event.target.value
				}
			}));
		}
    };

        render() {
		    const { productsGeneral, error, productGeneralName, sizeGeneralName,  sizeGeneralSlag, sizeGeneralStock } = this.state;
            const isInvalid =
			(productsGeneral && productsGeneral.perPageCollections) === '' ||
			(productsGeneral && productsGeneral.perPageSellerProducts) === '';

            const isInvalidSizes = productGeneralName === '' || sizeGeneralName === '' ||sizeGeneralSlag === '' || sizeGeneralStock === '';

            return (
            <div>
				<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
					<span className="navbar-brand">Productsizes</span>
				</nav>
                <div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
					<div className="card mb-3">
						<div className="card-body">
                            <AdminSettingsNavigation />
                            <h4>Product Sizes</h4>
							<hr />
                            <div className="pl-4 mb-5">
								<div className="form-group mb-2">
									<label className="mb-1">Collections page <span className="text-danger">*</span></label>
									<input
										name="perPageCollections"
										defaultValue={productsGeneral && productsGeneral.perPageCollections}
										onChange={this.onChange}
										type="text"
										className="form-control w-25"
									/>
								</div>
								<div className="form-group mb-2">
									<label className="mb-1">Seller Products page <span className="text-danger">*</span></label>
									<input
										name="perPageSellerProducts"
										defaultValue={productsGeneral && productsGeneral.perPageSellerProducts}
										onChange={this.onChange}
										type="text"
										className="form-control w-25"
									/>
								</div>
							</div>
							<form>
								<div className="form-group mb-3">
									<label className="font-weight-bold">Manage Product Sizes:</label>
								</div>
								<div className="pl-4 mb-5">
									<div className="form-group mb-2">
										<label className="mb-1">Product Name <span className="text-danger">*</span></label>
										<input
											name="productGeneralName"
											value={productGeneralName}
											onChange={this.onChange}
											type="text"
											className="form-control w-25"
										/>
									</div>	
									<div className="form-group mb-2">
										<label className="mb-1">Size Name <span className="text-danger">*</span></label>
										<input
											name="sizeGeneralName"
											value={sizeGeneralName}
											onChange={this.onChange}
											type="text"
											className="form-control w-25"
										/>
									</div>
                                    <div className="form-group mb-2">
										<label className="mb-1">Size Slag <span className="text-danger">*</span></label>
										<input
											name="sizeGeneralSlag"
											value={sizeGeneralSlag}
											onKeyPress={(event) => noSpace(event)}
											onChange={this.onChange}
											type="text"
											className="form-control w-25"
										/>
									</div>
                                    <div className="form-group mb-2">
										<label className="mb-1">Size Stock <span className="text-danger">*</span></label>
										<input
											name="sizeGeneralStock"
											value={sizeGeneralStock}
											onChange={this.onChange}
											type="text"
											className="form-control w-25"
										/>
									</div>
								
									<button disabled={isInvalidSizes} type="submit" className="btn btn-dark" onClick={this.onSubmitProductSize}>
										Add Products Size
									</button>
									
									<div className="border p-3 mt-3">
										{productsGeneral.productSizes
											?
												<div>
													{productsGeneral.productSizes.map((size, index) => (
														<span className="py-2 px-3 mr-1 mb-1 bg-purple d-inline-block" key={index}>
															<a>Product Name:{size.id}{" "}Product Size Name:{size.name}Product Size Slag:{" "}{size.slag} Product Size Stock:{" "}
                                                            {size.stock}</a>
															<Link to="#" className="badge badge-light ml-2" onClick={(event) => this.onDeleteProductGeneralSize(event, index)}>
																<i className="material-icons align-middle font-weight-bold" style={{fontSize: '10px'}}>close</i>
															</Link>
														</span>
													))}
												</div>
											:
												<div className="text-danger">No Size available</div>
										}
									</div>
								</div>
							</form>
                            <hr />
							
							<button disabled={isInvalid} type="submit" className="btn btn-dark" onClick={this.onSubmit}>
								Update Settings
							</button>
                            							
							{error && <div className="alert alert-warning" role="alert">{error.message}</div>}
							
                            </div>
                        </div>
                    </div>
               </div>
            );
        }
}


const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.ADMIN);

const ADMINPRODUCTSGENERALFORM = compose(
        withAuthorization(condition),
        withFirebase,
    )(ADMINPRODUCTSGENERAL);
    
export default ADMINPRODUCTSGENERALFORM;