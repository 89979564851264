import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

class imgauth extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			authUser: JSON.parse(localStorage.getItem('authUser')),
		};
	}
    componentDidMount() {
    }

    render() {	
        return(

<AuthUserContext.Consumer>
				{authUser =>
					authUser ? (
						<imgAuth authUser={authUser} />
					) : (
						<imgNonAuth />
					)
				}
				</AuthUserContext.Consumer>
                )
            }
        }
const imgAuth = ({ authUser }) => (
    <div>

{authUser.roles.includes(ROLES.CUSTOMER) && (
<div></div>
)}        
    </div>
);

const imgNonAuth = () => (
<div>
 
 <img src='https://firebasestorage.googleapis.com/v0/b/testshop-81d04.appspot.com/o/images%2Flogo%2F5g3o4a?alt=media&token=9ec28f1f-5f66-4216-a067-f95f619ea25a'></img>
</div>
);




export default withFirebase(imgauth);

export {  imgNonAuth, imgAuth }