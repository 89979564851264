import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Navigation from '../Navigation';
import { NavLink } from 'react-router-dom';
import CollectionsPage from '../Collections';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AccountSellerPage from '../Account/seller';
import Products from '../Products';
import CreateProduct from '../Products/create';
import UpdateProduct from '../Products/update';
import ADMINPRODUCTSGENERAL from '../Products/Sizes';
import ViewProduct from '../Products/view';
import Cart from '../Cart';
import Checkout from '../Checkout';
import CheckoutSuccess from '../Checkout/success';
import AdminSettingsGeneral from '../Admin/settings/general';
import AdminSettingsProduct from '../Admin/settings/product';
import AdminSettingsCheckout from '../Admin/settings/checkout';
import AdminSettingsSitephotos from '../Admin/settings/sitephotos';
import AdminUsers from '../Admin/users/index';
import AdminUsersSingle from '../Admin/users/single';
import CustomerOrders from '../Orders/customer';
import CustomerOrdersView from '../Orders/customerView';
import SellerOrders from '../Orders/seller';
import SellerOrdersView from '../Orders/sellerView';
import NotFound from '../NotFound';
import {imgauth, imgNonAuth, imgAuth } from '../imgauth';
import * as ROUTES from '../../constants/routes';
import { withAuthentication,AuthUserContext } from '../Session';
import { ProductsGeneralContext, withProductsGeneral } from '../ProductsGeneral'
import { withSettings, SettingsContext } from '../Settings';
import { compose } from 'recompose';


const App = () => (
	<Router basename="/">

		<div className="row no-gutters d-flex">
			<div className="col-lg-2 col-md-3 flex-fill">
			
				<a href="#collapseNav" 
				   data-toggle="collapse" 
				   className="btn btn-link d-none d-block d-md-none text-left text-dark border-bottom" 
				   role="button" 
				   aria-expanded="false" 
				   aria-controls="collapseNav">
					   <i className="material-icons align-middle">menu</i>
				</a>
				
				<div className={"nav-sidebar collapse brand-container " + (window.innerWidth <= 768 ? '' : 'show')} id="collapseNav">
					<nav className="navbar navbar-light d-block border-bottom">
					
						{/* <SettingsContext.Consumer>
							{settings => (
								<Link className="navbar-brand d-block text-center mr-0" to="/">

									<div>{settings && settings.siteLogoUrl ? <img src={settings.siteLogoUrl} alt="Logo" height="160" class="img-fluid rounded-circle"/> : <i>ArmBrust Pro Shop</i>}</div>
							
								</Link>
							)}
						</SettingsContext.Consumer>	 */}
		
					</nav>
					<Navigation />

				</div>
			</div>
			
			<div className="col-lg-10 col-md-9 flex-fill">
			<Navigation />
				<Switch>
					<Route exact path={ROUTES.COLLECTIONS} component={CollectionsPage} />
					<Route path={ROUTES.SIGN_UP} component={SignUpPage} />
					<Route path={ROUTES.SIGN_IN} component={SignInPage} />
					<Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
					<Route path={ROUTES.HOME} component={HomePage} />
					<Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
					<Route path={ROUTES.ACCOUNT_SELLER} component={AccountSellerPage} />
					<Route exact path={ROUTES.PRODUCTS} component={Products} />
					<Route path={ROUTES.CREATE_PRODUCT} component={CreateProduct} />
					<Route path={ROUTES.UPDATE_PRODUCT} component={UpdateProduct} />
					<Route path={ROUTES.VIEW_PRODUCT} component={ViewProduct} />
					<Route path={ROUTES.ADMIN_SETTINGS_GENERAL} component={AdminSettingsGeneral} />
					<Route path={ROUTES.ADMIN_SETTINGS_PRODUCT} component={AdminSettingsProduct} />
					<Route path={ROUTES.ADMIN_SETTINGS_CHECKOUT} component={AdminSettingsCheckout} />
					<Route path={ROUTES.ADMIN_SETTINGS_SITEPHOTOS} component={AdminSettingsSitephotos} />
					<Route path={ROUTES.PRODUCT_SIZES_PRODUCTSIZES} component={ADMINPRODUCTSGENERAL} />
					<Route exact path={ROUTES.ADMIN_USERS} component={AdminUsers} />
					<Route path={ROUTES.ADMIN_USERS_SINGLE} component={AdminUsersSingle} />
					<Route path={ROUTES.CART} component={Cart} />
					<Route exact path={ROUTES.CHECKOUT} component={Checkout} />
					<Route exact path={ROUTES.CHECKOUT_SUCCESS} component={CheckoutSuccess} />
					<Route exact path={ROUTES.CUSTOMER_ORDERS} component={CustomerOrders} />
					<Route path={ROUTES.VIEW_CUSTOMER_ORDER} component={CustomerOrdersView} />
					<Route exact path={ROUTES.SELLER_ORDERS} component={SellerOrders} />
					<Route path={ROUTES.VIEW_SELLER_ORDER} component={SellerOrdersView} />
					<Route component={NotFound} />
				</Switch>
			</div>
		</div>
	</Router>
);

const AppBase = compose(
	withAuthentication, /* Set auth in localstorage  */
	withSettings,
	withProductsGeneral, /* Set settings in localstorage  */
)(App);

export default AppBase;