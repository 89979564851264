import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import parse from 'html-react-parser';

class CheckoutSuccess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: {},
			loading: true,
			authUser: JSON.parse(localStorage.getItem('authUser')),
			settings: JSON.parse(localStorage.getItem('settings')),
		};
	}
	
	componentDidMount() {
		let order_id = this.props.match.params.id;
		let user_id = this.state.authUser.uid;
		
		if(order_id){
			document.title = 'Success #' + order_id;
			
			/* Fetch order from our Firebase realtime database  */
			/* this.props.firebase.order() -- the "order" is taken from firebase.js  */
			this.props.firebase.order(order_id).get().then(snapshot => { /* Use get() to only call it once (disable realtime) */
				const orderObject = snapshot.data();
				
				if(orderObject && orderObject.customerID === user_id){
					this.setState({
						order: orderObject,
						orderID: order_id,
						loading: false
					});
				} else {
					this.props.history.push(ROUTES.NOT_FOUND);
				}
				
			});
		}
	}
	
	render() {
		const { loading, settings, order, orderID } = this.state;
		
		return (
			<div style={{backgroundColor: "#f2f2f2"}}>
				{loading ? <div>Loading...</div> : 
					<div>
						<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
							<span className="navbar-brand">Order Status</span>
						</nav>
						
						<div className="container-fluid py-3">
							<div className="card mb-3">
								<div className="card-body">
									{order && order.paymentOption === 'Bank Transfer' &&
										<div>
											{settings && settings.bankTransferInstructions &&
												<div>
													<div className="text-center mb-3">
														<h3 className="text-success">Order Submitted</h3>
														<Link to={{pathname: '/orders/customer/view/' + orderID}} className="text-center font-weight-bold">View Order Details</Link>
													</div>
													<hr />
													{parse(settings.bankTransferInstructions)}
												</div>
											}
										</div>
									}
									
									{order && order.paymentOption === 'Cash On Delivery' && 
										<div>
											{settings && settings.cashOnDeliveryInstructions &&
												<div>
													<div className="text-center mb-3">
														<h3 className="text-success">Order Submitted</h3>
														<Link to={{pathname: '/orders/customer/view/' + orderID}} className="text-center font-weight-bold">View Order Details</Link>
													</div>
													<hr />
													{parse(settings.cashOnDeliveryInstructions)}
												</div>
											}
										</div>
									}
									
									{order && order.paymentOption === 'Paypal' && 
										<div>
											{settings && settings.cashOnDeliveryInstructions &&
												<div>
													<div className="text-center mb-3">
														<h3 className="text-success">Order Completed</h3>
														<Link to={{pathname: '/orders/customer/view/' + orderID}} className="text-center font-weight-bold">View Order Details</Link>
													</div>
												</div>
											}
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		);
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.SELLER);

export default compose(
	withAuthorization(condition),
	withFirebase,
)(CheckoutSuccess);