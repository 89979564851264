import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import cogoToast from 'cogo-toast';

const INITIAL_STATE = {
	loading: false,
	loadingBtn: false,
	totalItemsCount: 0,
	lastItem: null,
	showMoreBtn: true,
	orders: [],
	searchFilter: '',
}
	
class CustomerOrders extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...INITIAL_STATE,
			authUser: JSON.parse(localStorage.getItem('authUser'))
		};
		
		this.timer = null;
	}
	
	componentDidMount() {
		document.title = 'Orders';
		
		this.setState({ loading: true });
		this.loadCustomerOrders(false, true);
	}
	
	/* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
	componentWillUnmount() {
		this.unsubscribeOrders();
	}
	
	loadCustomerOrders(loadmore, fromComponent) {
		const { lastItem, searchFilter, authUser } = this.state;
		const self = this;
		
		var pageLimit = 5;
		
		this.setState({ loadingBtn: true });
		
		/* Load first */
		var dataQuery = this.props.firebase.orders();
		
		
		switch(searchFilter) {
			case 'ASC':
				dataQuery = dataQuery.orderBy('createdAt');
				break;
			case 'DESC':
				dataQuery = dataQuery.orderBy('createdAt', 'desc');
				break;
			default:
				dataQuery = dataQuery.orderBy('createdAt');
				
		}
		
		dataQuery = dataQuery.where('customerID', '==', authUser.uid).limit(pageLimit);
		
		/* If there's a last item set, we start the query after that item using startAfter() method */
		if( loadmore && lastItem ){
			dataQuery = dataQuery.startAfter(lastItem); 
		}
		
		this.unsubscribeOrders = dataQuery.onSnapshot(snapshot => { /* The onSnapshot() method registers a continuous listener that triggers every time something has changed, use get() to only call it once (disable realtime) */
			let orderChunk = [];
			
			snapshot.docChanges().forEach(function(change) {
				if (change.type === "added") {
					/* Add more items to the screen... */
					orderChunk.push({ ...change.doc.data(), oid: change.doc.id });
				} else if (change.type === "modified") {
					/* If there is a change in realtime... */
					/* Apply the modification to the item directly without changing the current item index. */
					self.setState({
						orders: self.state.orders.map(el => (el.oid === change.doc.id ? {...change.doc.data(), oid: change.doc.id} : el))
					});
				}
			});
			
			this.setState((prevState) => ({
				orders: prevState.orders && fromComponent ? [...prevState.orders, ...orderChunk]: orderChunk,
				loading: false,
				loadingBtn: false,
				lastItem: snapshot.docs[snapshot.docs.length -1 ],
				showMoreBtn: orderChunk.length < pageLimit ? false : true, 
			}));
		});
	}
	
	onChangeFilter = event => {
		clearTimeout(this.timer);
		this.setState({searchFilter: event.target.value});
		this.timer = setTimeout(this.loadCustomerOrders.bind(this), 100);
	}

	onCancelOrder = id => {
		if(window.confirm('Are you sure you want to cancel this order #' + id + '?, this action cannot be undone once started.')){
			this.props.firebase.order(id).get().then(snapshotOrder => {
				var order = snapshotOrder.data();
				var statusArray = order.others.map(el => (el.status === 'Pending' || el.status === 'On hold' ? 1 : 0));
				var newOthersArray = [];
				
				if (order.paymentOption === 'Paypal'){
					alert('Sorry, payments made via Paypal can no longer be cancelled. .');

				} else if(statusArray.includes(1)){
					/* Only allow update if none of the sellers in this order are already processing or on hold the order */
					/* Set all to Cancelled */
					order.others.forEach((item, index) => {
						newOthersArray.push({sellerID: item.sellerID, status: "Cancelled"});
					});
					this.props.firebase.order(id).update({'others': newOthersArray, 'isCancelled': true});
					
					/* Bring back the stock */
					order.cart.forEach((item) => {
						this.props.firebase.product(item.id).update({'stock': this.props.firebase.fieldValue.increment(parseInt(item.quantity)) });
					});
					
					cogoToast.success("Order successfully cancelled");
					
				} else {
					alert('Sorry, you can no longer cancel this order.');
				}
			});
		}
	}
	
	render() {
		const { orders, loading, loadingBtn, showMoreBtn, searchFilter } = this.state;
		return (
			<div style={{backgroundColor: "#f2f2f2"}}>
				{loading ? <div>Loading ...</div> : 
					<React.Fragment>
						<nav className="navbar navbar-dark" style={{backgroundColor: "#6200ee"}}>
							<span className="navbar-brand">Orders</span>
						</nav>
						<div className="container-fluid px-lg-3">
							<div className="row">
								<div className="col-xl-2 col-lg-3 py-3 bg-white">
									<div className="form-group">
										<label className="font-weight-bold">Date Added:</label>
										<select className="form-control form-control-sm" name="category" onChange={this.onChangeFilter} value={searchFilter}>
											<option value="ASC">Ascending</option>
											<option value="DESC">Descening</option>
										</select>
									</div>
								</div>
									
								<div className="col-xl-10 col-lg-9">
									<div className="table-responsive">
										<table className="table res-tbl">
											<thead>
												<tr>
													<th>Order</th>
													<th>Payment Option</th>
													<th>Date Created</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{orders.length > 0 && orders.map(orders => (
													<tr key={orders.oid}>
														<td data-label="Order"><Link to={{pathname: '/orders/customer/view/' + orders.oid}} className="font-weight-bold">#{orders.oid}</Link></td>
														<td data-label="Payment Option">{orders.paymentOption}</td>
														<td data-label="Date Created">{new Date(orders.createdAt).toLocaleDateString('en-US')} {new Date(orders.createdAt).toLocaleTimeString()}</td>
														<td data-label="Action">
															{orders.isCancelled 
																? <button className="btn btn-outline-secondary btn-sm" disabled>Cancelled</button>
																: <button className="btn btn-outline-danger btn-sm" onClick={() => this.onCancelOrder(orders.oid)}>Cancel Order</button>
															}
														</td>
													</tr>
												))}
											</tbody>
										</table>
										
										<div className="col text-center mb-3">
											{showMoreBtn 
												? 
													<button 
														className="btn btn-purple font-weight-bold btn-lg mt-3"  disabled={loadingBtn}
														onClick={() => this.loadCustomerOrders(true, true)}
													>
														{loadingBtn ? <span>Loading...</span> : <span>Load More</span>}
													</button>
												:
													<div className="mt-3">{orders.length ? <span>End of result</span> : <span>No results found</span>}</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</React.Fragment>
				}
			</div>
		);
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.CUSTOMER);

export default compose(
	withAuthorization(condition),
	withFirebase,
)(CustomerOrders);